import React from 'react';
import styled from '@emotion/styled';
import Layout from '../components/layout/layout';

const Heading = styled.h1`
  color: #084f87;
  font-size: 22px;
  margin: 0;
`;
const Span = styled.h2`
  color: #084f87;
  font-size: 18px;
`;
class Contact extends React.Component {
  render() {
    return (
      <Layout>
        <Container>
          <header>
            <Heading>Contact us today.</Heading>
            <Span>
              Give us a call or send us an email to book your next appointment
              or answer any questions.
            </Span>
          </header>
          <form
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact"
          >
            <FlexContainer>
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <label>
                Name
                <Input type="text" name="name" id="name" />
              </label>
              <label>
                Email
                <Input type="email" name="email" id="email" />
              </label>
              <label>
                Subject
                <Input type="text" name="subject" id="subject" />
              </label>
              <label>
                Message
                <Input2 name="message" id="message" rows="5" />
                <div>
                  <Button type="reset" value="Clear">
                    Clear
                  </Button>
                  <Button2 type="submit">Send</Button2>
                </div>
              </label>
            </FlexContainer>
          </form>
        </Container>
      </Layout>
    );
  }
}
const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 2vw calc((100vw - 800px) / 2);
  height: auto;
  justify-content: center;
  @media (max-width: 768px) {
    margin: 0;
    padding: 20px;
  }
`;

export default Contact;

const FlexContainer = styled.div`
  display: flex;

  flex-direction: column;
  > label {
    padding: 5px 0px;
  }
  > button {
    margin: 10px 0;
  }
`;

const Input2 = styled.textarea`
  width: 100%;
  border-radius: 7px;

  margin-top: 5px;
  border: 1px black solid;
`;
const Input = styled.input`
  margin-top: 5px;
  width: 100%;
  height: 25px;
  border-radius: 7px;

  border: 1px black solid;
`;

const Button = styled.button`
  border: none;
  font-size: 15px;
  border-radius: 7px;
  text-transform: uppercase;
  padding: 5px 15px;
  margin: 15px 5px;
  &:nth-child(1) {
    margin-left: 0px;
  }
`;
const Button2 = styled.button`
  border: none;
  font-size: 15px;
  border-radius: 7px;
  text-transform: uppercase;
  padding: 5px 15px;
  margin: 15px 5px;
  background: #1d5b2b;
  color: white;
  &:nth-child(1) {
    margin-left: 0px;
  }
`;
